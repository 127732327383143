
<template>
    <v-card flat  :class="tab.BoxTransparency+' mx-3'"  :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">

            <component v-bind:is="TableComponent" :IntranetView="IntranetView"
            @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
            :HasCalendarView="HasCalendarView" :HasVCardView="HasVCardView"
            :Data="TableData" :Headers="TableHeaders" :TableName="TableName" :PrimaryField="PrimaryField"
            :TableCollectionRef="TableCollectionRef" :CanCreate="tab.EntitySource === 'SubCollection' ? tab.ViewersCanUpdate : CanCreate"
            @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
            @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"  
            @RefreshComponent="RefreshComponent"
            :PageView="true" :PageFilter="TablePageFilter" :AppisDarkMode="AppisDarkMode"  :CompSize="TableCompsize" :id="tab.Entityid"/>
    </v-card>
  
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: [{TableComponent: {			
			type: String,
			required: true
		}},'DynamicWikiData','ParentTab','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','SystemEntities'],
    components: {

        },
    data() {
    return {  
        CanCreate: false,
        HasCalendarView: false,
        HasVCardView: false,
        PrimaryField: '',
        MasterData: [],
        MasterHeaders: [],
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        // PrimaryField(){
        //     if(this.tab.EntitySource === 'SubCollection'){
        //         let subcol = this.DynamicWikiData.SubCollections.find(obj => obj.id === this.tab.Entityid)
        //         if(subcol && subcol.RecordPrimaryField){
        //             return subcol.RecordPrimaryField.Name
        //         }   
        //     }
        //     else if(this.tab.EntitySource === 'System Routes'){
        //         let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
        //         if(entity && entity.RecordPrimaryField){
        //             return entity.RecordPrimaryField.Name
        //         }
        //     }
            
        // },
        TableCollectionRef(){
            //this.DynamicWikiData
            if(this.tab.Entityid && !this.tab.Dynamic && this.tab.EntitySource === 'System Routes'){			
                return db.collection(this.tab.Entityid.split('_').join('').toLowerCase())		
            }
            else if(this.tab.Entityid && this.tab.Dynamic && this.tab.EntitySource === 'SubCollection'){			
                return db.collection(this.DynamicWikiData.Collection).doc(this.$route.params.id).collection(this.tab.Entityid)
            }            
        },
        TableName(){
            return this.tab.Entityid
        },  
        TableData(){
            if(this.tab.Entityid && !this.tab.Dynamic){	
                // let storearrayname = this.tab.Entityid.split('_').join('')+'Array'
                // console.log(storearrayname,this.$store.state[storearrayname],this.$store.state)		
                return this.MasterData		
            }
            else if(this.tab.Entityid && this.tab.Dynamic){		
                // let tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
                // this.MasterData = tableobj.Data
                return this.MasterData.map(item => {
                    item.CanView = false
                    item.CanEdit = false
                    item.CanDelete = false
                    if(this.tab.ViewersCanUpdate){
                        item.CanView = true
                        item.CanEdit = true
                        item.CanDelete = false
                    }
                    return item
                })
            }
        },
        TableHeaders(){
            if(this.tab.Entityid && !this.tab.Dynamic){			
                // return []	
                let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                
                let headers = this.MasterHeaders
                return headers
            }
            else if(this.tab.Entityid && this.tab.Dynamic){			
                 let tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
                let headers = this.MasterHeaders
                return headers
            }
        },
      TableComponent(){
        if(this.tab.Entityid && !this.tab.Dynamic){			
            //return () => import(`@/views/${this.tab.Entityid.split('_').join('')}.vue`);
            return () => import(`@/components/Database/DataTableViewer.vue`);			
        }
        else if(this.tab.Entityid && this.tab.Dynamic){			
            return () => import(`@/components/Database/DataTableViewer.vue`);			
        }
      },
      TablePageFilter(){
          if(this.tab.Entityid && this.tab.ContentFilter){
               let FilterObj = {
                FieldName: this.tab.ContentFilter.Name,
                FieldType: this.tab.ContentFilter.Type,
                Filter: this.tab.ContentFilter.ContentFilterValue              
                }
                return FilterObj
          }
      },
      TableCompsize(){
        if(this.tab.Entityid){
          let comp = document.getElementById(this.tab.Entityid)
            if(comp){
            return this.$vuetify.breakpoint.width
            //return comp.offsetWidth
            }
            else{
                return this.$vuetify.breakpoint.width
            //return this.$vuetify.breakpoint.width/this.ParentTab.Elements.length
            }
        }
      },
    //   TablePageFilter(){
    //     if(this.tab.Entityid){		
    //         return this.tab.Entityid.split('_').join('')}+'PageFilter'			
    //     }	
    //   },
    //   TableCompsize(){
    //     if(this.tab.Entityid){			
    //         return this.tab.Entityid.split('_').join('')}+'size'			
    //     }	
    //   },
    //   TablePageFilter(){
    //     if(this.tab.Entityid){			
    //         return this.tab.Entityid.split('_').join('')}+'PageFilter'
    //     }
    //   },
    //   TableCompsize(){
    //     if(this.tab.Entityid){			
    //         return this.tab.Entityid.split('_').join('')}+'size'
    //     }
    //   },
    //   Tableid(){
    //        if(this.tab.Entityid){			
    //             return this.tab.Entityid.split('_').join('')}+'comp'
    //         }
    //   },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
        this.PrepareTable()
    },
    
    methods: {
        RefreshComponent(boolean){
            this.$emit('RefreshComponent',boolean,this.tab)
        },
        PrepareTable(){
            this.PreparePrimaryField()            
            this.PrepareHeaders()
            this.PrepareData()
            
            
        },
        PreparePrimaryField(){
            if(this.tab.EntitySource === 'SubCollection'){
                let subcol = this.DynamicWikiData.SubCollections.find(obj => obj.id === this.tab.Entityid)
                if(subcol && subcol.RecordPrimaryField){
                    this.PrimaryField = subcol.RecordPrimaryField.Name
                }   
            }
            else if(this.tab.EntitySource === 'System Routes'){
                let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                if(entity && entity.RecordPrimaryField){
                    this.PrimaryField = entity.RecordPrimaryField.Name
                }
            }
        },
        PrepareHeaders(){
            if(this.tab.EntitySource === 'SubCollection'){
                let tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
                let headers = tableobj.Headers.map(header => {
                    header.class = 'overline'
                    header.propvalue = header.value
                    return header
                })
                let actionheader = {
                    text: 'Actions',
                    value: 'actions',
                    class: 'overline'
                }
                let Primaryheader = {
                    text: '',
                    value: 'RecordPrimaryField',
                    class: 'overline'
                }
                if(this.tab.ViewersCanUpdate){
                    let primaryon = headers.find(obj => obj.value === 'RecordPrimaryField')
                    if(!primaryon){
                        headers.splice(0,0,Primaryheader)
                    }
                }
                let oncheck = headers.find(obj => obj.value === 'actions')
                if(!oncheck){
                    headers.push(actionheader)
                }
                let primaryheader = headers.find(obj => obj.Primary)                
                this.MasterHeaders = headers   
                this.MasterHeaders.splice(0,0,primaryheader)
                console.log(this.MasterHeaders)
            }
            else if(this.tab.EntitySource === 'System Routes'){
            let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                
            this.MasterHeaders = entity.AllFields.filter(field => {
                return field.IsHeader
            }).map((header,headerindex) => {
                header.text = header.DisplayName,
                header.propvalue = header.Name
                header.value = header.Name,
                header.class = 'overline'
                if(header.Type === 'Option Set'){
                    header.value = header.Name+'.Name'
                    if(header.DefaultOption){
                        header.Response = header.DefaultOption
                    }
                    console.log('header Option Set',header)
                    if(header.HasDeepHierarchy && header.Levels && header.Levels[1] && header.DefaultLevel2Option){
                        header.Level1Response = header.DefaultLevel2Option
                    }
                }
                else if(header.Type === 'Lookup'){
                    header.value = header.Name+'.'+header.LookupFieldName
                }
                return header
            })
            let actionheader = {
                    text: 'Actions',
                    value: 'actions',
                    class: 'overline'
                }
            let oncheck = this.MasterHeaders.find(obj => obj.value === 'actions')
                if(!oncheck){
                    this.MasterHeaders.push(actionheader)
                }
            //     let statusfield = entity.AllFields.find(obj => obj.Name === 'Status')
            //     statusfield = {
            //         text: 'Status',
            //         value: 'Status.Name',
            //         class: 'overline'
            //     }
                
            // let statusheader = this.MasterHeaders.find(obj => obj.value === 'Status')
            //     if(!statusheader){
            //         this.MasterHeaders.push(statusfield)
            //     }
            //     else{
            //         let statusheaderindex = this.MasterHeaders.indexOf(statusheader)
            //     }	
            // let primaryheader = this.MasterHeaders.find(obj => obj.value === this.PrimaryField) 
            
            // let currentprimaryindex = this.MasterHeaders.indexOf(primaryheader)
            // console.log(currentprimaryindex,primaryheader)
            // this.MasterHeaders.splice(0,0,this.MasterHeaders.splice(currentprimaryindex,1)[0])
            this.MasterHeaders = this.MasterHeaders.sort((a, b) => {			
                    // console.log('sorting again')			
                    // console.log(a.Value)			
                    var key1 = a.HeaderIndex			
                    var key2 = b.HeaderIndex			

                    if (key1 > key2) {			
                        return -1;			
                    } else if (key1 == key2) {			
                        return 0;			
                    } else {			
                        return 1;			
                    }			
            })	
            console.log(this.MasterHeaders)
            }
            
        },
        PrepareData(){
            let vm = this
            console.log(this.tab)
             if(this.tab.EntitySource === 'System Routes'){	
                let storearrayname = this.tab.Entityid.split('_').join('')+'Array'
                this.MasterData = this.$store.state[storearrayname]
                console.log(vm.MasterData)
                if(!this.$store.state[storearrayname] || this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
                    
                    let storequery = this.tab.Entityid.split('_').join('')+'Query'
                    //console.log('ssems array is empty for '+storearrayname+'. Lets try query '+storequery)
                    if(this.$store.state[storequery]){
                        ///console.log('getting a query for '+storequery)
                        let payload = {                
                        query: vm.$store.state[storequery],
                        arraymutation: 'set'+storearrayname,
                        getter: 'get'+storearrayname,
                        arrayname: storearrayname
                        }
                        vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                            console.log(vm.$store.state[storearrayname])
                            vm.MasterData = vm.$store.state[storearrayname]
                            //vm.AccountsArray = vm.AccountsStore
                            vm.LookupHeadersPrep()
                            vm.DataInteractionPrep()
                            })
                    }
                }
                else{
                    vm.MasterData = vm.$store.state[storearrayname]
                    console.log(vm.MasterData)
                    vm.LookupHeadersPrep()
                    vm.DataInteractionPrep()
                }
             }    
             else if(this.tab.EntitySource === 'SubCollection'){		
                let tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
                this.MasterData = tableobj.Data
             }            
        },
        GetCustomRolesTableInteraction(Data){
            let rules = [
                'CanCreate',
                'CanView',
                'CanEdit',
                'CanDelete'
            ]
            return Data.map(data => {
                $store.state.formrules.map(rule => {
                    console.log(this.tab.Entityid.split('_').join('')+rule,this.$store.state)
                    data[rule] = this.$store.state[this.tab.Entityid.split('_').join('')+rule]
                    
                })
                return data                        
            })
        },
        GetBUTableInteraction(Data){
            
            let rules = [
                {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'CanList', PermissionLevel: 4},
                {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'CanList', PermissionLevel: 3},
                {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'CanList', PermissionLevel: 2},
                {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'CanList', PermissionLevel: 1},
                {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'CanGet', PermissionLevel: 4},
                {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'CanGet', PermissionLevel: 3},
                {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'CanGet', PermissionLevel: 2},
                {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'CanGet', PermissionLevel: 1},
                {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
                {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
                {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
                {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},
                {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'CanEdit', PermissionLevel: 4},
                {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'CanEdit', PermissionLevel: 3},
                {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'CanEdit', PermissionLevel: 2},
                {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'CanEdit', PermissionLevel: 1},
                {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'CanDelete', PermissionLevel: 4},
                {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'CanDelete', PermissionLevel: 3},
                {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'CanDelete', PermissionLevel: 2},
                {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'CanDelete', PermissionLevel: 1},
            ]
            return Data.map(data => {
                $store.state.formrules.map(rule => {
                    //console.log(rule.PermissionLevel,rule.Action,this.tab.Entityid.split('_').join('')+rule.Boolean,this.$store.state[this.tab.Entityid.split('_').join('')+rule.Boolean])
                    if(rule.PermissionLevel === 4){
                       if(this.$store.state[this.tab.Entityid.split('_').join('')+rule.Boolean]){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 3){
                       if(this.$store.state[this.tab.Entityid.split('_').join('')+rule.Boolean] && this.userBUChildren.includes(data.Business_Unitid)){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 2){
                       if(this.$store.state[this.tab.Entityid.split('_').join('')+rule.Boolean] && data.Business_Unitid === this.userBUID){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 1){
                       if(this.$store.state[this.tab.Entityid.split('_').join('')+rule.Boolean] && data.Ownerid === this.userLoggedIn.id){
                            data[rule.Action] = true          
                        } 
                    }
                    
                })
                return data                        
            })
        },
        DataInteractionPrep(){
            //So below I have the structure I must interrogate. 
            //I get the security structure from router. But perhaps t's even eaier to get from the storeconfig docs, for future modularity on router
            //go it, must call tablesecuritystructure
            let tablesecuritystructure = ''
            if(this.tab.EntitySource === 'System Routes'){	
                let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                tablesecuritystructure = entity.datasetsecuritystructure
                console.log(tablesecuritystructure)
                if(tablesecuritystructure === 'Custom Roles'){
                    this.MasterData = this.GetCustomRolesTableInteraction(this.MasterData)
                    this.CanCreate = this.$store.state[this.tab.Entityid.split('_').join('')+'CanCreate']
                }
                else if(tablesecuritystructure === 'Business Unit'){
                    this.MasterData = this.GetBUTableInteraction(this.MasterData)
                    let CreateRules = [{Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
                    {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
                    {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
                    {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},]
                    CreateRules.map(rule => {
                        if(this.$store.state[this.tab.Entityid.split('_').join('')+rule.Boolean]){
                            this.CanCreate = true
                        }
                    })
                }
            }
           
        },
    LookupHeadersPrep(){
        let vm = this
        vm.MasterHeaders = vm.MasterHeaders.map(header => {
                        if(header.Type === 'Lookup'){
                            console.log('header is lookup',header)
                            let headerarray = vm.$store.state[header.RelatedRecord+'Array']
                            console.log(headerarray,header.RelatedRecord+'Query',vm.$store.state[header.RelatedRecord+'Query'])
                            if(!headerarray || headerarray && headerarray.length === 0){
                                if(vm.$store.state[header.RelatedRecord+'Query']){
                                    console.log('here is lookup query for empty array ',vm.$store.state[header.RelatedRecord+'Query'])
                                    let lookuppayload = {                
                                    query: vm.$store.state[header.RelatedRecord+'Query'],
                                    arraymutation: 'set'+header.RelatedRecord+'Array',
                                    getter: 'get'+header.RelatedRecord+'Array',
                                    arrayname: header.RelatedRecord+'Array'
                                    }
                                    vm.$store.dispatch('GetCollectionArray',lookuppayload).then(function(result) {
                                    //console.log(vm.$store.state[storearrayname])
                                    header.Array = headerarray
                                    })
                                }
                                
                            }
                            else{
                                header.Array = headerarray
                            }
                        }
                        return header
                    })
    },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    